import { Component, Vue } from "vue-property-decorator";

import { SideNav } from "@/layout";
import { QuizModel } from "@/models";
import { Common } from "../Common";

type response = { [key: string]: unknown };

@Component<AdminStudentLearningController>({
  components: {
    SideNav,
  }
})
export default class AdminStudentLearningController extends Vue {
  private resultList: object = {};
  private num = 0;
  private pageHtml = "";
  private stid = this.$route.query.stid as string;
  private token = window.localStorage.getItem('adminToken') as string;

  public async created() {
    const item: { [key: string]: string } = {
      page: this.$route.query.page as string,
      stid: this.stid,
      token: this.token,
    }
    const data = await QuizModel.getLearningResultList(item) as response;
    Common.checkToken(data.ERR_CODE as number);
    this.num = data.num as number;
    this.resultList = data.list as object;
    this.pageHtml = data.pageHtml as string;
  }
}
